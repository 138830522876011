<template>
  <div>
    <div v-if="!nativeLabel" class="font-weight-medium mt-3 mb-1">{{ label }}</div>
    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="internal"
      width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="mt-2"
          v-model="internal"
          prepend-icon="mdi-calendar"
          :label="nativeLabel ? label : null"
          readonly
          dense
          :messages="messages"
          v-bind="attrs"
          v-on="on"
          :rules="rules"
        ></v-text-field>
      </template>
      <v-date-picker v-model="internal" :locale="locale" scrollable :disabled="readonly">
        <v-spacer></v-spacer>
        <v-btn v-if="!readonly" text color="primary" @click="internal = null">
          {{ $t('label.clear') }}
        </v-btn>
        <v-btn v-if="!readonly" text color="primary" @click="modal = false">
          {{ $t('label.cancel') }}
        </v-btn>
        <v-btn text color="primary" @click="handleSave">
          {{ $t('label.ok') }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import i18n, { locales } from '@/i18n';

export default {
  name: 'ToggableDatePicker',
  props: {
    value: String,
    label: String,
    rules: Array,
    messages: Array | String,
    nativeLabel: Boolean,
    readonly: Boolean
  },
  data() {return {
    modal: false,
    isSaving: false
  }},
  computed: {
    locale() {return i18n.locale;},
    hasContent() {
      return !!this.value;
    },
    internal: {
      get() { return this.value; },
      set(val) { this.$emit('input', val);}
    }
  },
  methods: {
    handleSave() {
      if(this.readonly) {
        this.modal = false;
        return;
      }
      this.$refs.dialog.save(this.internal);
      this.isSaving = true;
      this.$emit('save', this.value);
    },
    markAsSaved() {
      this.isSaving = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-box {
  margin: -8px;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
}
</style>